import gql from "graphql-tag";
const getAllStories = gql`
  query GetAllStories {
    success_stories {
      age
      story_id
      source_url
      title
    }
  }
`;

const getFilteredStories = gql`
  query GetFilteredStories($minAge: Int, $maxAge: Int) {
    success_stories(where: { age: { _gte: $minAge, _lte: $maxAge } }) {
      age
      story_id
      source_url
      title
    }
  }
`;

export { getAllStories, getFilteredStories };
