import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const AgeSelectorContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px auto;
  .select {
  }
`;

function AgeSelector({ setSelectedAgeRange }) {
  return (
    <AgeSelectorContainer>
      <select
        onChange={e => {
          setSelectedAgeRange(e.target.value);
        }}
      >
        {generateAgeSelectorOptions(AGE_RANGE, setSelectedAgeRange)}
      </select>
    </AgeSelectorContainer>
  );
}

AgeSelector.propTypes = {
  setSelectedAgeRange: PropTypes.func.isRequired
};

function generateAgeSelectorOptions(ageRange) {
  const OptionsElements = ageRange.map(age => (
    <option key={age} value={age}>{`${age}-${age + 9}`}</option>
  ));
  const defaultOption = (
    <option key="all" value="all">
      All ages
    </option>
  );
  return [defaultOption, ...OptionsElements];
}

const AGE_RANGE = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

export default AgeSelector;
