import React, { useState } from "react";
import { Provider } from "urql";
import styled from "styled-components";
import AgeSelector from "../AgeSelector/AgeSelector";
import StoryList from "../StoryList/StoryList";
import client from "../../db/client";

const AppStyle = styled.div`
  h1 {
    font-size: 16px;
    text-align: center;
  }
  ol {
    list-style-type: none;
  }
`;

function App() {
  const [selectedAgeRange, setSelectedAgeRange] = useState("all");
  return (
    <AppStyle>
      <h1>Am I Too Old to Become a Developer?</h1>
      <AgeSelector setSelectedAgeRange={setSelectedAgeRange} />
      <StoryList selectedAgeRange={selectedAgeRange} />
    </AppStyle>
  );
}

function wrappedAppWithProvider() {
  return (
    <Provider value={client}>
      <App />
    </Provider>
  );
}

export default wrappedAppWithProvider;
