import React from "react";
import { useQuery } from "urql";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loader from "react-loader-spinner";
import {
  getAllStories,
  getFilteredStories
} from "../../queries/successStoriesQueries";

const StoryListDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

function StoryList({ selectedAgeRange }) {
  const [res] = useQuery({
    query: selectStoryListQuery(selectedAgeRange),
    variables: selectStoryListQueryVariables(Number(selectedAgeRange))
  });
  if (res.fetching)
    return (
      <LoaderContainer>
        <Loader type="Watch" color="#3F9142"></Loader>
      </LoaderContainer>
    );
  if (res.error) return `${res.error.message}`;
  return (
    <div className="StoryList">
      <StoryListDisplay>{generateStoriesFromAllStories(res)}</StoryListDisplay>
    </div>
  );
}

StoryList.propTypes = {
  selectedAgeRange: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

function selectStoryListQuery(selectedAgeRange) {
  if (!Number.isNaN(selectedAgeRange)) {
    return getFilteredStories;
  } else {
    return getAllStories;
  }
}

function selectStoryListQueryVariables(selectedAgeRange) {
  if (!Number.isNaN(selectedAgeRange)) {
    return {
      minAge: selectedAgeRange,
      maxAge: selectedAgeRange + 9
    };
  } else {
    return {};
  }
}

const NoSuccessStories = styled.div`
  text-align: center;
`;

function generateStoriesFromAllStories(allStories) {
  if (!allStories.data.success_stories.length)
    return (
      <NoSuccessStories>
        <p>Nothing yet</p>
        {"If you have a relevant success story, fill in this "}
        <a target="noreferer _blank" href="https://forms.gle/dPrVKPRN5ZtgWpE17">
          form
        </a>

        {" and I'll add it to the database."}
      </NoSuccessStories>
    );
  return allStories.data.success_stories.map(story => {
    return <Story story={story} key={story.story_id} />;
  });
}

const StoryContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: #e3f9e5;
  position: relative;
  text-align: left;
  font-size: 16px;
  width: 90%;
  max-width: 512px;
  min-width: 256px;
  margin: 12px;
  margin-bottom: 24px;
  padding: 16px;
  padding-bottom: 24px;
  border-radius: 4px;
  :hover {
    background-color: #c1eac5;
    cursor: pointer;
  }
  a {
    text-decoration: none;
    color: #222222;
  }
  .ageBubble {
    text-align: center;
    font-size: 14px;
    min-width: 24px;
    border-radius: 50%;
    padding: 4px;
    color: white;
    background-color: #2f8132;
    position: absolute;
    bottom: -10px;
    left: 50%;
    font-weight: strong;
  }
`;

function Story({ story }) {
  const { age, source_url, title } = story;
  return (
    <StoryContainer>
      <div className="ageBubble">{age}</div>
      <a href={source_url} target="noreferer _blank">
        {title}
      </a>
    </StoryContainer>
  );
}

Story.propTypes = {
  story: PropTypes.shape({
    age: PropTypes.number.isRequired,
    source_url: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default StoryList;
